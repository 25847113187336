import { Injectable } from '@angular/core';
import *  as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  secureKey = "F0rm|3u!43r";

  constructor() { }

  public saveData(key: string, value: any) {
   // console.log(value);
    if (typeof value == 'object') {
      localStorage.setItem(key, this.encrypt(JSON.stringify(value)));
    } else {
      localStorage.setItem(key, this.encrypt(value));
    }
  }

  public getData(key: string, type: string = 'string') {
   // console.log(localStorage.getItem(key));
    if (localStorage.getItem(key)) {
      let data = this.decrypt(localStorage.getItem(key));
      if (type == 'object') {
        data = JSON.parse(data);
      }
      return data;
    }
    return null;
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.secureKey).toString();
  }

  private decrypt(txtToDecrypt: any) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.secureKey).toString(CryptoJS.enc.Utf8);
  }
}