﻿//1. Import all dependencies
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'app/app-config.service';
import { NewServiceResponse } from 'app/core/models/new-service-response.model';

//2. The service class
@Injectable()
export class ProductService {
  
  
    private productAddUrl: string;
    private productAddToCartUrl: string;
    private getAddressUrl: string;
    private getProductInfoUrl: string;
    private getProductFilterListWithPaginationUrl: string;
    private getProductListWithPaginationUrl: string;
    private getProductRatingUrl: string;
    private getDeliveryData: string;
    private shopStatusMsg: string;
    private shopDetail: string;
    private scheduleDateUpdate: string;
    private filterInfo: string;
    private advanceFilterProducts: string;
    private partnerFilterProducts: string;
    private getAddProductDetailUrl: string;
    private getProductDetailUrl: string;
    private getShopProductsUrl: string;
    private getAlsoLikeProductsUrl: string;
    private shopsList: string;
    private partnerShopList: string;
    private fazaaShopsList: string;
    private activeAddress: string;
    private previousCity: string;
    private placeQrCodeOrder: string;
    private sendOtp: string;
    private verifyOtp: string;
    private fazaaProductList:string;
    
    // private testUrl: string;

    constructor(private _http: HttpClient, 
        private _configuration: Configuration) {

        this.productAddUrl = `${_configuration.WebApi}api/buyer/cart/API-addCart`;
        this.productAddToCartUrl = `${_configuration.WebApi}api/buyer/cart/API-addCartV1`;
        this.getAddProductDetailUrl = `${_configuration.WebApi}api/Buyer/RelatedProducts`;
        this.getProductDetailUrl = `${_configuration.WebApi}api/Buyer/listByProId`;
        this.getShopProductsUrl = `${_configuration.WebApi}api/Buyer/MoreFromShop`;
        this.getAlsoLikeProductsUrl = `${_configuration.WebApi}api/Buyer/ProductsYouMayLike`;
        this.getProductInfoUrl = `${_configuration.WebApi}api/merchant/product/productlistbyId`;
        this.getAddressUrl = `${_configuration.WebApi}api/buyer/cart/VerifyAddress`;
        this.getProductFilterListWithPaginationUrl = `${_configuration.WebApi}api/merchant/product/filterproductlistNew`;
        this.getProductListWithPaginationUrl = `${_configuration.WebApi}api/merchant/product/BuyerProductList`;
        this.getProductRatingUrl = `${_configuration.WebApi}api/Buyer/orders/GetProductRatingReviews`;
        this.getDeliveryData = `${_configuration.WebApi}api/buyer/cart/VerifyAddress`;
        this.scheduleDateUpdate = `${_configuration.WebApi}api/buyer/cart/API-UpdateScheduleDate`;
        // this.filterInfo = `${_configuration.WebApi}api/Product/GetFiltersData`;
        this.filterInfo = `${_configuration.WebApi}api/Product/GetFiltersDataV1`;
        this.advanceFilterProducts = `${_configuration.WebApi}api/merchant/product/AdvanceFilterV2`;        
        this.partnerFilterProducts = `${_configuration.WebApi}api/partner/product/AdvanceFilter`;

        this.shopDetail = `${_configuration.WebApi}api/Buyer/ShopDetailByStoreId`;
        // this.testUrl = `https://ajman.travel/umbraco/api/HappinessMeter/GetSecurityKey`;
        this.shopsList = `${_configuration.WebApi}api/buyer/PopularShops`;
        this.partnerShopList =`${_configuration.WebApi}api/partner/Shops`;
        this.fazaaProductList =`${_configuration.WebApi}api/merchant/product/fazaa`;
        this.fazaaShopsList = `${_configuration.WebApi}api/home/fazaashops`;
        this.activeAddress = `${_configuration.WebApi}api/buyer/Address/CustomerAddress`;
        this.previousCity = `${_configuration.WebApi}api/buyer/cart/CartDeliveryCity`;
        this.placeQrCodeOrder = `${_configuration.WebApi}api/Buyer/orders/placeQrCode`;
        this.sendOtp = `${_configuration.WebApi}api/guest/QrOTP`;
        this.verifyOtp = `${_configuration.WebApi}api/guest/VeirfyQROTPV1`;
    }

    verifyQrOtp(phone: string, otpCode: string): Observable<any> {
        let model ={
            phonenumber: phone,
            otp: otpCode
        }

        return this._http.post<any>(this.verifyOtp, model);
    }

    qrOtp(mobileNo: string, email: string): Observable<any> {
        return this._http.get<any>(this.sendOtp + '?MobileNo=' + mobileNo + '&email=' + email);
    }

    placeQrOrder(params): Observable<any> {
        return this._http.post<any>(this.placeQrCodeOrder, params)
    }

    getActiveAddress(userId): Observable<any> {
        return this._http.get<any>(this.activeAddress + "?UserId=" + userId)
    }

    checkPrevCity(userId): Observable<any> {
        return this._http.get<any>(this.previousCity + "?Id=" + userId)
    }

    getProductFilterListWithPagination(params): Observable<any> {
        return this._http.post<any>(this.getProductFilterListWithPaginationUrl, params)
    }

    getShopsListWithPagination(params): Observable<any> {
        return this._http.post<any>(this.shopsList, params)
    }

    getPartnerShopsListWithPagination(params): Observable<any> {
        return this._http.post<any>(this.partnerShopList, params)
    }

    getFazaaProductList(params): Observable<any> {
        return this._http.post<any>(this.fazaaProductList, params)
    }


    getFazaaShopsList(): Observable<any> {
        return this._http.get<any>(this.fazaaShopsList)
    }

    getDeliveryInfo(userId: any, storeId: any): Observable<any> {
        return this._http.get<any>(this.getDeliveryData + "?UserId=" + userId + "&StoreId=" + storeId)
    }

    getProductListWithPagination(params): Observable<any> {
        return this._http.post<any>(this.getProductListWithPaginationUrl, params)
    }

    // getTestUrl(): Observable<any> {
    //     return this._http.post<any>(this.testUrl, null)
    // }
    // public subject = new Subject<any>();
    // sendProductDetail(productDetail: string) {
    //     this.subject.next({ productDetail: productDetail });
    // }
    getProductInfo(productId: string): Observable<any> {
        return this._http.get<any>(this.getProductInfoUrl + "?ProductId=" + productId)
    }
    
    getAddProductDetail(productId: string): Observable<any> {
        return this._http.get<any>(this.getAddProductDetailUrl + "?ProductId=" + productId)
    }

    getProductDetail(productId: string): Observable<any> {
        return this._http.get<any>(this.getProductDetailUrl + "?ProductId=" + productId)
    }

    // getShop_AlsoLikeProducts(productId: string):Observable<any>{
    //     let moreProducts = forkJoin(
    //         this._http.get<any>(this.getShopProductsUrl  + "?ProductId=" + productId).share(),
    //         this._http.get<any>(this.getAlsoLikeProductsUrl  + "?ProductId=" + productId).share()
    //     );
    //     //console.log('HomeData:',homePageDetail)
    //     return moreProducts;
    // }
    getMoreFromShopProducts(productId: string): Observable<any> {
        return this._http.get<any>(this.getShopProductsUrl + "?ProductId=" + productId)
    }

    getYouMayAlsoLikeProducts(productId: string): Observable<any> {
        return this._http.get<any>(this.getAlsoLikeProductsUrl + "?ProductId=" + productId)
    }

    getShopInfo(storeId: string): Observable<any> {
        return this._http.get<any>(this.shopDetail + "?StoreId=" + storeId)
    }

    getFilterInfo(): Observable<any> {
        return this._http.get<any>(this.filterInfo)
    }

    getAdvanceFilterProducts(filter: any): Observable<any> {
        return this._http.post<any>(this.advanceFilterProducts, filter)
    }
    getPartnerAdvanceFilterProducts(filter: any): Observable<any> {
        return this._http.post<any>(this.partnerFilterProducts, filter)
    }


    getProductRating(productId: string): Observable<any> {
        return this._http.get<any>(this.getProductRatingUrl + "?ProductId=" + productId)
    }

    getAddress(userId: string): Observable<any>{
        return this._http.get<any>(this.getAddressUrl + "?UserId=" + userId)
    }

    addToCart(product: any){
        return this._http.post<any>(this.productAddUrl, product)
    }

    productAddToCart(product: any){
        return this._http.post<any>(this.productAddToCartUrl, product)
    }

    updateShopScheduleDate(product: any){
        return this._http.post<any>(this.scheduleDateUpdate, product)
    }

    shopStatus(shopStatusId, storeIsAcceptOrder){
        switch (shopStatusId) {
            case 1:
                //this.shopStatusMsg = dateTime;
                break;
            case 2:
                if(storeIsAcceptOrder){
                    this.shopStatusMsg = 'shop_overloaded_accept_order';
                }else{
                    this.shopStatusMsg = 'shop_overloaded_no_order';
                }
                break;
            case 3:
                if (storeIsAcceptOrder) {
                    this.shopStatusMsg = 'shop_closed';
                } else {
                    this.shopStatusMsg = 'shop_closed_no_order';
                }
                break;
            case 4:
                if (storeIsAcceptOrder) {
                    this.shopStatusMsg = 'shop_closed';
                } else {
                    this.shopStatusMsg = 'shop_closed_no_order';
                }
                break;
        }
        return this.shopStatusMsg;
    }
}