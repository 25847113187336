import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ConsumerModule } from '../../../+consumer/consumer.module';
import { routing } from './consumer.routing';
import {RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModelComponent } from './model/model.component';
import { NgbdRatingBasicComponent } from './ngbd-rating-basic/ngbd-rating-basic.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotesComponent } from './notes/notes.component';
import { LocalService } from 'app/core/sevices/local.service';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    ModelComponent,
    NgbdRatingBasicComponent,
    NotesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent

  ],
  entryComponents: [ModelComponent, NotesComponent],
  providers:[LocalService]

})
export class ConsumerLayoutsModule { }
