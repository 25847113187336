import { Component, OnInit, EventEmitter } from '@angular/core';
import { UserService } from 'app/shared/user/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UtilMethods } from 'app/shared/utils/util-methods';
import { Configuration } from 'app/app-config.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  public closeEvent: EventEmitter<any> = new EventEmitter<any>();
  public userInfo: any;
  public userDetails: any;
  public supportForm: FormGroup;
  public supportModal: boolean = true;
  customerSupport:string;

  constructor(private _userService: UserService,
    public formBuilder: FormBuilder,
    private _translate: TranslateService,
    private utilMethods: UtilMethods,
    private _configuration:Configuration) {
    this.userInfo = this._userService.getCurrentUser();
    this.customerSupport = this._configuration.settings.CustomerCareEmail;
  }

  ngOnInit() {
  
  }

  closeModal() {
    this.closeEvent.emit({ changed: true });
  }

  openEmailSupport() {
    this.supportModal = false;
    if(this.userInfo != null){
   
    this.supportForm = this.formBuilder.group({
      Email: [this.userInfo.Email],
      Subject: ['', [Validators.required]],
      Body: ['', [Validators.required]]
    });
  }else{
    this.supportForm = this.formBuilder.group({
      Email: ['', [Validators.required]],
      Subject: ['', [Validators.required]],
      Body: ['', [Validators.required]]
    });

  }
  }

  async submit() {
    this.utilMethods.markControlsTouched(this.supportForm);
    if (this.supportForm.invalid) {
      let msg = await this._translate.get('enter_valid_email_details').toPromise<void>();
      this.utilMethods.notificationDataError(msg);
      return;
    }
    let data = this.supportForm.getRawValue();
    let emailDetails = {
      Subject: data.Subject,
      EmailBody: data.Body,
      Fromemail: data.Email
    };
    this._userService.sendEmail(emailDetails).subscribe(
      async srSuccess => {
        this.utilMethods.hideFullScreenLoader();
        let msg = await this._translate.get('email_success_update').toPromise<void>();
        this.utilMethods.notificationSuccess(msg);
        this.closeModal()
      },
      srError => {
        this.utilMethods.hideFullScreenLoader();
        this.utilMethods.notificationError(srError);
      }
    )
  }
}
