import { Injectable } from '@angular/core';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { User } from 'app/shared/user';
import { Router } from '@angular/router';
import { Configuration } from 'app/app-config.service';
import { HttpClient } from '@angular/common/http';
// import { ChatPaginationService } from '../../+merchant/chats/chat.service';
import { I18nService } from '../i18n/i18n.service';


interface ICache { [key: string]: BehaviorSubject<any>; }
type serializable = object | Object;

@Injectable({ providedIn: 'root' })
export class UserService {

    private errorMsg: string = "Some error occurred !!";
    public refreshSession: string;
    public user: Subject<any>;
    public UserInfoDetails: BehaviorSubject<User>;
    public imageRoute: string;
    public storeImageRoute: string;
    public productImageRoute: string;
    public userInfo: string;
    private cache: ICache;
    private supportUrl: string;
    private companyLogoUrl: string;

    constructor(private _router: Router,
        private _http: HttpClient,
        private i18N: I18nService,
        //private chatService: ChatPaginationService,
        private _configuration: Configuration) {
        this.supportUrl = `api/User/SendSupportEmail`;
        this.companyLogoUrl = `api/Store/GetLicesneinfo`;
        this.refreshSession = `api/merchant/UserDetail`;
        this.imageRoute = 'assets/img/store/';
        this.storeImageRoute =  'assets/img/store/';
        this.productImageRoute = 'UploadFiles/Products/';
        this.userInfo = `api/user/SocialAuth`;
        this.cache = Object.create(null);
        this.UserInfoDetails = new BehaviorSubject(JSON.parse(localStorage.getItem('LScurrentUser')));
    }

getWebAPIURL(){
    console.log("here"+this._configuration.API_BASEURL);
    return this._configuration.API_BASEURL ;
}
    getUpdatedInfo() {
        let user: any = this.getCurrentUser();
        this.getUpdatedSession(user.UserID)
            .subscribe((res: any) => {
                if (res.IsSuccess) {
                    this.setCurrentUser(res.Data);
                    localStorage.setItem("Token", res.Data.Token)

                }
            })
    }

    getComapnyLogo(comapny): Observable<any>{
        return this
            ._http.get(this._configuration.WebApi+this.companyLogoUrl + '?licname=' + comapny);
    }

    public get currentUserValue(): User {
        return this.UserInfoDetails.value;
    }

    setItem<T extends serializable>(key: string, value: T): BehaviorSubject<T> {
        localStorage.setItem(key, JSON.stringify(value));

        if (this.cache[key]) {
            this.cache[key].next(value);
            return this.cache[key];
        }

        return this.cache[key] = new BehaviorSubject(value);
    }

    getItem<T extends serializable>(key: string): BehaviorSubject<T> {
        if (this.cache[key])
            return this.cache[key];
        else
            return this.cache[key] = new BehaviorSubject(JSON.parse(localStorage.getItem(key)));
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
        if (this.cache[key])
            this.cache[key].next(undefined);
    }

    setCurrentUser(user: any) {
        this.UserInfoDetails.next(user);
        this.setItem('LScurrentUser', user);
    }

    getCurrentUser() {
        let temp: any = this.getItem('LScurrentUser').value;
        let allData: User;
        if (temp)
            allData = this.UserInfoDetails.value;
        return allData;
    }

    checkAuthorization(): boolean {

        let temp: any = this.getItem('LScurrentUser').value;
        if (!temp)
            return false;
        let allData: User;
        if (temp)
            allData = this.UserInfoDetails.value;
        if (allData.MerchantID == "00000000-0000-0000-0000-000000000000" || allData.MerchantID == null) {

            this._router.navigate(['']);
            return false;
        }
        else if (allData.StoreID == "00000000-0000-0000-0000-000000000000" || allData.StoreID == null) {
            return true;
        }
        return true;
    }

    logout() {
        // try {
        //     if (this.getCurrentUser())
        //         this.chatService.removeToken(this.getCurrentUser().StoreID, this.i18N.currentLanguage);
        // } catch (e) { console.log(e) }
        this.removeItem('LScurrentUser');
        this.removeItem('UserID');
        localStorage.removeItem("Token")
        this.UserInfoDetails = new BehaviorSubject<any>(null);
    }

    getUpdatedSession(userId): Observable<any> {
        return this
            ._http.get(this._configuration.API_BASEURL+this.refreshSession + '?UserId=' + userId, {});
    }
    getUserDetails(userId) {
        return this
            ._http.get(this._configuration.WebApi+this.userInfo + '?SocialId=' + userId);
    }

    sendEmail(emailDetails: any): Observable<any> {
        return this
            ._http.post(this._configuration.WebApi+this.supportUrl, emailDetails);
    }

    stickyFunction() {
        setInterval(function () {
            if (window.innerWidth < 768) {
                var elmnt = document.getElementById("mobFix");
                var elmnt2 = document.getElementById("stickyBarBottomFix");
                // debugger;
                if (typeof (elmnt) != 'undefined' && elmnt != null) {
                    elmnt.classList.add("stuckBottom");
                    var elmntHeight = elmnt.offsetHeight;
                    elmnt2.style.marginTop = elmntHeight + "px";
                    // console.log(elmnt);
                } else {
                    elmnt2.style.marginTop = '0px';
                    // console.log(elmnt);
                }
            } else {
                var elmnt = document.getElementById("mobFix");
                var elmnt2 = document.getElementById("stickyBarBottomFix");
                elmnt2.style.marginTop = '0px';
                // console.log(elmnt);
                if (typeof (elmnt) != 'undefined' && elmnt != null) {
                    elmnt.classList.remove("stuckBottom");
                    elmnt2.style.marginTop = '0px';
                    // console.log(elmnt);
                }
            }
        }, 1500);
    }
}