import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'app/app-config.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  

    private getAddressList: string;
    private getAddressListV1: string;
    private delCusAdd: string;
    private setDefaultUserAddress: string;
    private selectedAddress: string;
    private getCitiesList: string;
    private getCityAreaUrl: string;
    // private getSearchUrl: string; 
    private cartItemsList: string;
    private cartItemsListV1: string;
    private Vat: string;
    private addEditAddress: any;
    private guestAddAddress: any;
    private orderItemsList: any;
    private summaryItems: any;
    private deleteCartItem: any;
    private getItemDetail: any;
    private placeUserOrder: any;
    private cashDeliveryCharge: any;
    private promo: any;
    private getAlsoLikeProductsUrl: any;
    private paymentStatus: any;
    private phoneNoVerify: any;
    otpUrl: string;
    guestOtpUrl: string;
    verifyProfileOtpUrl: string;
    verifyGuestOtpUrl: string;
    updateCartInstructions: string;
    private telrGetUrl: any;
    
    

  constructor(private _http: HttpClient, 
    private _configuration: Configuration) { 
        this.getAddressList = `${_configuration.WebApi}api/buyer/Address/API-List`;
        this.getAddressListV1 = `${_configuration.WebApi}api/buyer/Address/API-ListV1`;
        this.getCitiesList = `${_configuration.WebApi}api/Country/GetAllCities`
        this.delCusAdd = `${_configuration.WebApi}api/buyer/Address/API-Delete`;
        this.setDefaultUserAddress = `${_configuration.WebApi}api/buyer/Address/API-DefaultAddress`;
        this.selectedAddress = `${_configuration.WebApi}api/buyer/cart/VerifyAddressV1`;
        this.getCityAreaUrl = `${_configuration.WebApi}api/Country/GetAllAreaByCityID/cityID`;
        this.cartItemsList = `${_configuration.WebApi}api/buyer/cart/CustomerAddressVerify`;
        this.cartItemsListV1 = `${_configuration.WebApi}api/buyer/cart/CustomerAddressVerifyV1`;
        this.Vat = `${_configuration.WebApi}api/merchant/store/GetVat`;
        this.addEditAddress = `${_configuration.WebApi}api/buyer/Address/API-Add`;
        this.guestAddAddress = `${_configuration.WebApi}api/guest/checkout`;
        this.orderItemsList = `${_configuration.WebApi}api/Buyer/orders/GetOrderSummary`;
        this.summaryItems = `${_configuration.WebApi}api/buyer/cart/Summary`;
        this.deleteCartItem = `${_configuration.WebApi}api/buyer/cart/API-DeleteCart`;
        this.getItemDetail = `${_configuration.WebApi}api/Buyer/dashboard/listByProId`;
        // this.placeUserOrder = `${_configuration.WebApi}api/Buyer/orders/PlaceOrder`;
        this.placeUserOrder = `${_configuration.WebApi}api/Buyer/orders/PlaceOrderV1`;
        this.cashDeliveryCharge = `${_configuration.WebApi}api/Akshaak/GetAppConfiguration`;
        this.promo = `${_configuration.WebApi}api/Promo/Voucher/ApplyVoucher`;
        this.getAlsoLikeProductsUrl = `${_configuration.WebApi}api/Buyer/ProductsYouMayLike`;
        this.paymentStatus = `${_configuration.WebApi}api/Buyer/orders/PaymentStatus`;
        this.phoneNoVerify = `${_configuration.WebApi}api/buyer/VerifyPhone`;
        this.otpUrl = `${_configuration.WebApi}api/merchant/VerificationOTPCodeV1`;
        this.guestOtpUrl = `${_configuration.WebApi}api/guest/RequestOTP`;
        this.verifyProfileOtpUrl =  `${_configuration.WebApi}api/merchant/VeirfyProfileOTPV1`;
        this.verifyGuestOtpUrl = `${_configuration.WebApi}api/guest/VerifyGuestOTP`;
        this.updateCartInstructions = `${_configuration.WebApi}api/cart/UpdateCart`;
        this.telrGetUrl = `${_configuration.WebApi}api/Buyer/gateway/telr`;
        
    }

    // private messageSource = new BehaviorSubject<any>('');
    // currentMessage = this.messageSource.asObservable();

    // // public totalAmt = new BehaviorSubject<any>("");
    // passTotalAmt(amt: any) {
    //     // this.totalAmt = amt;
    //     this.messageSource.next(amt);
    // }

    // getTotalAmt() {
    //     // this.totalAmt = amt;
    //   return this.messageSource.getValue();
    // }

    getTelrIframeUrl(orderNo): Observable<any> {
        return this._http.get<any>(this.telrGetUrl + '?ordNumber=' + orderNo);
    }

    sendGuestOtp(mobileNo: string, email: string): Observable<any> {
        return this._http.get<any>(this.guestOtpUrl + '?MobileNo=' + mobileNo + '&email=' + email);
    }

    verifyGuestOtp(phone: string, otpCode: string): Observable<any> {
        let model ={
            PhoneNo: phone,
            OTPUser: otpCode
        }

        return this._http.post<any>(this.verifyGuestOtpUrl , model);
    }

    otpConfirmation(mobileNo: string, email: string, name: string): Observable<any> {
        return this._http.get<any>(this.otpUrl + '?MobileNo=' + mobileNo + '&EmailID=' + email + '&Name=' + name);
    }

    verifyProfileOtp(phone: string, otpCode: string): Observable<any> {
        let model ={
            PhoneNo: phone,
            OTPUser: otpCode
        }

        return this._http.post<any>(this.verifyProfileOtpUrl , model);
    }

    sendPaymentDetail(params): Observable<any> {
        return this._http.post<any>(this.paymentStatus, params)
    }

    updateStoreInstructions(params): Observable<any> {
        return this._http.post<any>(this.updateCartInstructions, params)
    }

    placeOrder(params): Observable<any> {
        return this._http.post<any>(this.placeUserOrder, params)
    }

    getYouMayAlsoLikeProducts(productId: string): Observable<any> {
        return this._http.get<any>(this.getAlsoLikeProductsUrl + "?ProductId=" + productId)
    }

    verifyPhoneNo(phoneNo: string, userId: string): Observable<any> {
        return this._http.get<any>(this.phoneNoVerify + "?Number=" + phoneNo + "&UserId=" + userId)
    }

    getProductDetail(ShopItemId: any) {
        return this
        ._http.get<any>(this.getItemDetail + "?proId=" + ShopItemId)
      }

    getUserAddressList(params):Observable<any>{
        return this
        ._http.get<any>(this.getAddressList + "?UserId=" + params)
    }

    getUserAddressListV1(params):Observable<any>{
        return this
        ._http.get<any>(this.getAddressList + "?UserId=" + params)
    }

    delCartItem(itemId):Observable<any>{
        return this
        ._http.get<any>(this.deleteCartItem + "?ItemId=" + itemId)
    }

    getCartItemsList(params):Observable<any>{
        return this
        ._http.get<any>(this.cartItemsList + "?UserId=" + params)
    }

    getCartItemsListV1(params):Observable<any>{
        return this._http.get<any>(this.cartItemsListV1 + "?UserId=" + params);
    }

    getOrderItemsList(params):Observable<any>{
        return this
        ._http.get<any>(this.orderItemsList + "?UserId=" + params)
    }

    getSummaryItems(params):Observable<any>{
        return this
        ._http.get<any>(this.summaryItems + "?UserId=" + params)
    }

    getVat(){
        return this
        ._http.get<any>(this.Vat)
    }

    getDeliveryCharge(){
      return this
      ._http.get<any>(this.cashDeliveryCharge)
    }

    getPromoDiscount(params):Observable<any>{
        return this._http.post<any>(this.promo, params)
    }

    getAllCitiesData() {
        return this
        ._http.get<any>(this.getCitiesList)
      }

    delUserAddress(params):Observable<any>{
        return this
        ._http.get<any>(this.delCusAdd + "?cusAddId=" + params)
    }

    getCityAreas(cityId: string): Observable<any> {
        return this._http.get<any>(this.getCityAreaUrl + "?CityId=" + cityId);
    }

    setDefaultAddress(params): Observable<any> {
        return this._http.post<any>(this.setDefaultUserAddress, params)
    }

    userSelectedAddress(userId, cusAddId): Observable<any> {
        return this._http.get<any>(this.selectedAddress + "?UserId=" + userId + "&CusAddID=" + cusAddId)
    }

    editAddAddress(params): Observable<any> {
        return this._http.post<any>(this.addEditAddress, params)
    }

    addGuestAddress(params): Observable<any> {
        return this._http.post<any>(this.guestAddAddress, params)
    }

    validateTransactionId(params): Observable<any> {
        return this._http.post<any>(`${this._configuration.WebApi}api/Buyer/orders/PlaceOrder`,params);
    }

    shopStatus(shopStatusId, storeIsAcceptOrder){
        let shopStatusMsg
        switch (shopStatusId) {
            case 1:
                //this.shopStatusMsg = dateTime;
                break;
            case 2:
                if(storeIsAcceptOrder){
                    shopStatusMsg = 'shop_overloaded_accept_order';
                }else{
                    shopStatusMsg = 'shop_overloaded_no_order';
                }
                break;
            case 3:
                if (storeIsAcceptOrder) {
                    shopStatusMsg = 'shop_closed';
                } else {
                    shopStatusMsg = 'shop_closed_no_order';
                }
                break;
            case 4:
                if (storeIsAcceptOrder) {
                    shopStatusMsg = 'shop_closed';
                } else {
                    shopStatusMsg = 'shop_closed_no_order';
                }
                break;
        }
        return shopStatusMsg;
    }

}
