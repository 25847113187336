import { NgModule, ApplicationRef, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing'
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState } from './app.service';

// Core providers
import { CoreModule } from "./core/core.module";
import { SmartadminLayoutModule } from "./shared/layout/layout.module";


import { ModalModule } from 'ngx-bootstrap/modal';
//import { MaterialModule } from '@angular/material';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient,HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SmartadminModule } from './shared/smartadmin.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MomentModule } from 'ngx-moment';
import { TermsComponent } from './+merchant/+store/dialogs/terms/terms.component';
import { MoreDescriptionComponent } from './+merchant/+store/dialogs/more-description/more-description.component';
import { UpdateStatusComponent } from './+merchant/+order/dialogs/update-status/update-status.component';
import { RejectOrderComponent } from './+merchant/+order/dialogs/reject-order/reject-order.component';
import { NotesComponent } from './+merchant/+order/dialogs/notes/notes.component';
import { PdfViewerComponent } from './+merchant/+order/dialogs/pdf-viewer/pdf-viewer.component';
import { HttpInterceptorMiddleWare } from './shared/utils/http-interceptor';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { SocialLoginModule, AuthServiceConfig } from "angular-6-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angular-6-social-login";
import { ChatPaginationService } from './+merchant/chats/chat.service';
import { AngularFirestore, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'environments/environment';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateReturnComponent } from './+merchant/+order/dialogs/update-return/update-return.component';
import { AcceptReturnComponent } from './+merchant/+order/dialogs/accept-return/accept-return.component';
import { VerifyOtpComponent } from './+merchant/+setting/+modals/+verify-otp/verify-otp.component';
import { UserTokenService } from './+auth/+services/user-token.service';
import { NgxMaskModule } from 'ngx-mask';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { AddVariantComponent } from './+merchant/+product/+add-variant/add-variant.component';
import { TagInputModule } from 'ngx-chips';
import { ListVariantImagesComponent } from './+merchant/+product/+list-variant-images/list-variant-images.component';
/******External pulgin*****/
import { ColorPickerModule } from 'ngx-color-picker';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { EEmpowerComponent } from './e-empower/e-empower.component';
import { Configuration } from './app-config.service';

let config = new AuthServiceConfig([

    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("836267556748758")
    },
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("864205900432-d323p8btcu2julqloii296c7bgulmj5c.apps.googleusercontent.com")
    },
]);
// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState,
    ChatPaginationService,
    AngularFirestore
];

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function provideConfig() {
    return config;
}

export const httpInterceptorProvider = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorMiddleWare,
        multi: true
    },
];
export function initializeApp(appConfigService: Configuration) {
    return (): Promise<any> => { 
      return appConfigService.loadConfig();
    }
  }
/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        TermsComponent,
        MoreDescriptionComponent,
        UpdateStatusComponent,
        RejectOrderComponent,
        NotesComponent,
        AddVariantComponent,
        PdfViewerComponent,
        UpdateReturnComponent,
        AcceptReturnComponent,
        VerifyOtpComponent,
        ListVariantImagesComponent,
        MarketPlaceComponent,
        EEmpowerComponent,
    ],
    entryComponents: [
        TermsComponent,
        UpdateStatusComponent,
        UpdateReturnComponent,
        RejectOrderComponent,
        NotesComponent,
        AddVariantComponent,
        PdfViewerComponent,
        MoreDescriptionComponent,
        AcceptReturnComponent,
        VerifyOtpComponent,
        ListVariantImagesComponent
    ],
    imports: [ // import Angular's modules
        BrowserModule,
        //MaterialModule,
        BrowserAnimationsModule,
        LazyLoadImageModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ModalModule.forRoot(),
        NgxLinkifyjsModule.forRoot(),
        CoreModule,
        NgxSpinnerModule,
        NgxMaskModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireFunctionsModule,
        AngularFireDatabaseModule,
        AngularFireMessagingModule,
        ToastrModule.forRoot({
            maxOpened: 2,
            timeOut: 3000,
            preventDuplicates: true
        }),
        NgbModule.forRoot(),
        SmartadminModule,
        SmartadminLayoutModule,
        MomentModule,
        routing,
        TagInputModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        SocialLoginModule,
        BrowserModule,
      //  ServiceWorkerModule.register('OneSignalSDKWorker.js', { enabled: environment.production }),
        ColorPickerModule,
        DeviceDetectorModule.forRoot()
    ],
    exports: [
        MomentModule,
        LazyLoadImageModule
    ],
    providers: [
        UserTokenService,
        APP_PROVIDERS,
        httpInterceptorProvider,
        { provide: FirestoreSettingsToken, useValue: {} },
        Configuration,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [Configuration],
            multi: true,       
        },
       // Configuration,
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        }        
    ]
})

export class AppModule {
    constructor(public appRef: ApplicationRef, public appState: AppState) {
    }
}

