import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Configuration {

  public WebApi: string;
  public API_BASEURL:string;
  public projectName:string;
  public settings:any;
  constructor(private http: HttpClient) { }

  loadConfig(): Promise<any> {

    return new Promise<void>((resolve, reject) => {
      this.http.get('./assets/appsettings.json').toPromise().then((response: any) => {
        this.WebApi = response.WebApi;
        this.API_BASEURL =response.API_BASEURL;
        this.projectName = response.Project;
        this.settings = response;
      //  console.log('Config Loaded');
     //   console.log(this.WebApi, response.API_BASEURL);
        resolve();

      }).catch((response: any) => {
        reject(`Could not load the config file`);
      });
    });

  }
}