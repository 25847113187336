import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { ModuleWithProviders } from "@angular/core";
import { CheckAuthGuard } from './_guards/check-auth.guard';
import { ShopComponent } from './shop/shop.component';
import { UserGuideComponent } from './shared/user/user-guide/user-guide.component';
import { CustomPreloadingService } from './shared/utils/custom-preloading.service';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { EEmpowerComponent } from './e-empower/e-empower.component';


export const routes: Routes = [
    
    {
        path: '',
        children: [
            // {
            //         path: '',
            //         redirectTo: 'consumer',
            //         pathMatch: 'full',
            //         data: {
            //             titleEn: 'Welcome to Akshaak',
            //             titleAr: 'Welcome to Akshaak'
            //         }
            // },
            {
                path: '',
                loadChildren: 'app/+consumer/consumer.module#ConsumerModule',
                // data: { 
                //     titleEn: 'akshaak.com - Online Shopping in UAE | Akshaak is all about homemade, authentic & creative products',
                //     titleAr: 'akshaak.com - Online Shopping in UAE | Akshaak is all about homemade, authentic & creative products'
                //  }
            },
            // {
            //     path: '',
            //     redirectTo: 'welcome',
            //     pathMatch: 'full',
            //     data: {
            //         titleEn: 'Welcome to Akshaak',
            //         titleAr: 'Welcome to Akshaak'
            //     }
            // }, 
            // {
            //     path: 'welcome',
            //     pathMatch: 'full',
            //     loadChildren: 'app/welcome/welcome.module#WelcomeModule',
            //     data: {
            //         titleEn: 'Welcome to Akshaak',
            //         titleAr: 'Welcome to Akshaak'
            //     }
            // },
            // {
            //     path: ':shopName',
            //     component: ShopComponent,
            //     //pathMatch: 'full',
            //     loadChildren: 'app/shop/shop.module#ShopModule',
            //     data: {
            //         titleEn: 'Welcome to shop',
            //         titleAr: 'Welcome to shop'
            //     }
            // },
            // {
            //     path: 'sell',
            //     loadChildren: 'app/market-place/market-place.module#MarketPlaceModule',
            // },

            // {
            //     path: 'coming-soon',
            //     loadChildren: 'app/coming-soon/coming-soon.module#ComingSoonModule',
            //     data: {
            //         titleEn: 'Akshaak Coming Soon',
            //         titleAr: 'Akshaak Coming Soon'
            //     }
            // },
            // {
            //     path: 'offers/:bannerName',
            //     loadChildren: 'app/+consumer/consumer.module#ConsumerModule',
            //     data: {
            //         titleEn: 'Welcome to Akshaak',
            //         titleAr: 'Welcome to Akshaak'
            //     },
            // },
            // {
            //     path: 'fazaastore',
            //     loadChildren: 'app/+consumer/consumer.module#ConsumerModule',
            //     data: {
            //         titleEn: 'Welcome to Akshaak',
            //         titleAr: 'Welcome to Akshaak'
            //     },
            // },
            {
                path: 'offers',
                loadChildren: 'app/+consumer/consumer.module#ConsumerModule',
                // data: {
                //     titleEn: 'Welcome to Fazaa',
                //     titleAr: 'Welcome to Fazaa'
                // },
            },
            {
                path: ':language/offers',
                loadChildren: 'app/+consumer/consumer.module#ConsumerModule',
                // data: {
                //     titleEn: 'Welcome to Akshaak',
                //     titleAr: 'Welcome to Akshaak'
                // },
            },
            {
                path: ':language/products',
                loadChildren: 'app/+consumer/consumer.module#ConsumerModule',
                // data: {
                //     titleEn: 'Welcome to Akshaak',
                //     titleAr: 'Welcome to Akshaak'
                // },
            },
            {
                path: ':language/e-empower',
                component: EEmpowerComponent,
                // data: {
                //     titleEn: 'Welcome to Akshaak',
                //     titleAr: 'Welcome to Akshaak'
                // },
            },
            {
                path: 'sell',
                component: MarketPlaceComponent,
                // data: {
                //     titleEn: 'Welcome to Akshaak',
                //     titleAr: 'Welcome to Akshaak'
                // },
            },

            {
                path: ':language/sell',
                component: MarketPlaceComponent,
                // data: {
                //     titleEn: 'Welcome to Akshaak',
                //     titleAr: 'Welcome to Akshaak'
                // },
            },

            {
                path: 'sell/:Company',
                component: MarketPlaceComponent,
                // data: {
                //     titleEn: 'Welcome to Akshaak',
                //     titleAr: 'Welcome to Akshaak'
                // }
            },
            {
                path: ':language/sell/:Company',
                component: MarketPlaceComponent,
                // data: {
                //     titleEn: 'Welcome to Akshaak',
                //     titleAr: 'Welcome to Akshaak'
                // }
            },

            {
                path: 'miscellaneous',
                loadChildren: 'app/+miscellaneous/miscellaneous.module#MiscellaneousModule',
                data: { pageTitle: 'Miscellaneous' }
            }, 
            {
                path: 'merchant',
                loadChildren: 'app/+merchant/merchant.module#MerchantModule',
                data: { titleEn: 'merchant' }
            },
            {
                path: 'consumer',
                loadChildren: 'app/+consumer/consumer.module#ConsumerModule',
                //data: { titleEn: 'consumer' }
            }
        ]
    },

    //{ path: 'UserManual/SellerGuide', component: UserGuideComponent},
    { path: ':language/auth', component: AuthLayoutComponent, loadChildren: 'app/+auth/auth.module#AuthModule' },
    { path: 'auth', component: AuthLayoutComponent, loadChildren: 'app/+auth/auth.module#AuthModule' },
    { path: ':ShopName', loadChildren: 'app/shop/shop.module#ShopModule' },
    { path: ':language/:ShopName', loadChildren: 'app/shop/shop.module#ShopModule' },

    // {
    //     path: ':ShopName',
    //     children: [
    //         {
    //             path: ':ShopName',
    //             redirectTo: ':ShopName',
    //             pathMatch: 'full',
    //             data: {
    //                 titleEn: 'Welcome to Akshaak',
    //                 titleAr: 'Welcome to Akshaak'
    //             }
    //         },
    //         {
    //             path: ':ShopName',
    //             //pathMatch: 'full',
    //             loadChildren: 'app/shop/shop.module#ShopModule',
    //             data: {
    //                 titleEn: 'Welcome to shop',
    //                 titleAr: 'Welcome to shop'
    //             }
    //         },
    //     ]
    // },
    { path: '**', redirectTo: 'miscellaneous/404' }

];

// export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
export const routing: ModuleWithProviders = RouterModule.forRoot(routes,{preloadingStrategy: CustomPreloadingService});
