import { Component, OnInit, Pipe, SecurityContext } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportComponent } from 'app/shared/layout/navigation/customer-support/support.component';
import { DashboardService } from 'app/+consumer/+dashboard/services/dashboard.service';
import { I18nService } from 'app/shared/i18n/i18n.service';
import { UtilMethods } from 'app/shared/utils/util-methods';
import { LayoutService } from 'app/shared/layout/layout.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ModelComponent } from 'app/shared/layout/consumer-layouts/model/model.component';
import { UserService } from 'app/shared/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Configuration } from 'app/app-config.service';


@Component({
  selector: 'app-marketplace',
  templateUrl: './market-place.component.html',
  styleUrls: ['./market-place.component.scss']
})

export class MarketPlaceComponent implements OnInit {
  public userId: string;
  public storeId: string;
  public merchantId: string;
  public langFlag: string;
  faqsList: any[] = [];
  modalRef: any;
  public videoPopup: boolean = false;
  public videoUrl: any;
  public company: any;
  public language: any;
  public decValCheck;
  project:string;
  sellerVideoURL:string;
  constructor(private _dashboardService: DashboardService,
    private sanitizer: DomSanitizer,
    private utilMethods: UtilMethods,
    private layoutService: LayoutService,
    private i18N: I18nService,
    private modalService: NgbModal,
    public _userService: UserService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private _configuration:Configuration) {
    $('.loaderWrap').fadeOut();
    this.project = this._configuration.projectName;
    this.sellerVideoURL = this._configuration.settings.SellerLandingVideoURL;
  }

  ngOnInit() {
    this.titleService.setTitle(this.project+" UAE - Create Your Online Store Now For Free");

    this.metaTagService.updateTag({
      name: 'description', content: this.project +" Home of Handmade & Homemade Products in UAE. Create Your Online Store Free Now with "+this.project+". Follow Your Passion and Start Your Business."
    });
    this.metaTagService.updateTag({
      name: 'keywords', content: "create online store free, create your online store"
    });

    this._activatedRoute.params.subscribe(params => {
      this.company = params["Company"];
      this.language = params["language"];
      //console.log('Company name', this.company);
      //console.log('language:', this.language);
      if (this.language == 'ar')
        this.language = 'ae';
      //console.log('Currentlanguage:', this.i18N.currentLanguage);
      if (this.i18N.currentLanguage != this.language && (this.language == 'en' || this.language == 'ae'))
        this.translate();
    });

    this.langFlag = this.i18N.currentLanguage;
    this.i18N.getLanguageUpdates().subscribe((lang) => {
      this.langFlag = lang.language;
    })

    if (localStorage.getItem('LScurrentUser')) {
      this.merchantId = JSON.parse(localStorage.getItem('LScurrentUser')).MerchantID;
      this.storeId = JSON.parse(localStorage.getItem('LScurrentUser')).StoreID;
      this.userId = JSON.parse(localStorage.getItem('LScurrentUser')).UserID;
    }

    this._dashboardService.getSellerFaqs().subscribe(res => {
      if (res.IsSuccess) {
        //console.log('Faqs:', res);
        this.faqsList = res.Data;
      }
    });

    // this._dashboardService.getFaqs().subscribe(res => {
    //   if(res.IsSuccess){
    //     console.log('Faqs:', res);
    //     this.faqsList = res.Data;
    //     for(let i=0; i < this.faqsList.length; i++){
    //       if(i == 0){
    //         this.faqsList[i].showModuleshow = true;
    //       }else{
    //         this.faqsList[i].showModuleshow = false;
    //       }
    //     }
    //     console.log('Faqs1:', this.faqsList);

    //   }
    // })

    let elem = document.getElementById("stickyBarBottom");
    let elem2 = document.getElementById("stickyBarBottomFix");
    elem.parentNode.removeChild(elem);
    elem2.parentNode.removeChild(elem2);
  }

  getCustomerSupport() {
    this.modalRef = this.modalService.open(SupportComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
    this.modalRef.componentInstance.closeEvent.subscribe(el => {
      this.modalRef.close();
    })
  }

  translate() {
    this.i18N.currentLanguage == 'ae' ? this.langFlag = 'en' : this.langFlag = 'ae';
    this.utilMethods.showFullScreenLoader();

    this.i18N.setLanguage(this.langFlag);
    this.layoutService.onRtlCus(this.langFlag);
    this.utilMethods.hideFullScreenLoader()
  }

  setVideoUrl(videoUrl) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  showVideoPopup(videoUrl) {
    this.videoPopup = true;
  }
  closeVideoPopup() {
    this.videoPopup = false;
  }

  becomeSeller() {
    this.utilMethods.showFullScreenLoader();

    if (this.merchantId == "00000000-0000-0000-0000-000000000000") {
      let cartDetails = {
        iconPath: '../../../../assets/icons/icon-192x192.png',
        heading: 'become_seller',
        primaryBtnText: 'yes',
        secondaryBtnText: 'no'
      }
      // tslint:disable-next-line: max-line-length
      this.modalRef = this.modalService.open(ModelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
      this.utilMethods.hideFullScreenLoader();
      this.modalRef.componentInstance.CartDetails = cartDetails;

      this.modalRef.componentInstance.submitEvent.subscribe(el => {
        this.utilMethods.hideFullScreenLoader()
        this._dashboardService.becomeSeller(this.userId).subscribe(res => {
          if (res.IsSuccess) {
            //console.log("MerchantCreated:", res.Data);
            this._userService.setCurrentUser(res.Data);
            this.utilMethods.showFullScreenLoader
            this._router.navigate(['/merchant/shop/add']);

          }
          //this.utilMethods.hideFullScreenLoader();
        })
        
        this.modalRef.close();
      })
      this.modalRef.componentInstance.closeEvent.subscribe(el => {
        this.modalRef.close();
      })
    } else {
      this._router.navigate(['/merchant/shop/add']);
    }
  }

  navigateToSeller() {
    if (localStorage.getItem('LScurrentUser')) {
      localStorage.setItem('BuyerSide', 'false');
      this.utilMethods.showFullScreenLoader();
      this._router.navigate(['/merchant/dashboard']);
    } else {
      this.utilMethods.hideFullScreenLoader();
      let modalDetails = {
        type: 'guestUser',
        iconPath: "../../../../assets/img/default_user.png",
        heading: "welcome_project",
        subs: "guest_signin_msg",
        //delType: this.cartProductDetail.DeliveryType,
        //previousDelType: this.cartProductDetail.PreviousDeliveryType,
        primaryBtnText: "sign_in",
        secondaryBtnText: "create_account",
        skipBtnText: "skip_now"
      }
      this.modalRef = this.modalService.open(ModelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'changePickupModal custom-class' });
      this.modalRef.componentInstance.CartDetails = modalDetails;
      this.modalRef.componentInstance.submitEvent.subscribe(el => {
        //console.log('Route', this._router.url)
        this._router.navigate(['/auth/login'], { queryParams: { returnUrl: this._router.url } });
        this.modalRef.close();
      })
      this.modalRef.componentInstance.registerEvent.subscribe(el => {
        this._router.navigate(['/auth/register']);
        this.modalRef.close();
      })
      this.modalRef.componentInstance.closeEvent.subscribe(el => {
        //this._router.navigate(['/consumer']);
        this.modalRef.close();
      })
      //this.modalRef.close();
    }
  }

  logout() {
    // this._userService.logout();
    this.utilMethods.showFullScreenLoader();
    if (this.company != undefined)
      this._router.navigate(['/auth/register/', this.company]);
    else
      this._router.navigate(['/auth/register']);
  }

}
