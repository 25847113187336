import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  ModalModule, ButtonsModule, TooltipModule, BsDropdownModule, ProgressbarModule, AlertModule, TabsModule
} from 'ngx-bootstrap'

import { I18nModule } from "./i18n/i18n.module";
import { UtilsModule } from "./utils/utils.module";
import { StatsModule } from "./stats/stats.module";
import { SmartadminWidgetsModule } from './widgets/smartadmin-widgets.module';
//import { ChatsComponent } from '../+merchant/chats/chat.component';
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "ngx-moment";
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';

@NgModule({
  imports: [
    CommonModule, FormsModule, RouterModule, TranslateModule, MomentModule,
    NgxLinkifyjsModule,
    UtilsModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    })
  ],
  declarations: [
    //ChatsComponent
  ],
  exports: [
    CommonModule, FormsModule, RouterModule,
    ModalModule,
    ButtonsModule,
    AlertModule,
    TabsModule,
    TooltipModule,
    BsDropdownModule,
    ProgressbarModule,
    I18nModule,
    SmartadminWidgetsModule,
    UtilsModule,
    StatsModule,
    //ChatsComponent
  ]
})
export class SmartadminModule { }
