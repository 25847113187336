import { Component, OnInit, Input } from '@angular/core';
import { I18nService } from 'app/shared/i18n';
import { UtilMethods } from 'app/shared/utils/util-methods';
import { LayoutService } from '../../layout.service';
import { DashboardService } from 'app/+consumer/+dashboard/services/dashboard.service';
import { Router } from '@angular/router';
import { CheckoutService } from 'app/+consumer/+checkout/services/checkout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportComponent } from 'app/shared/layout/navigation/customer-support/support.component';
import { v4 as uuid } from 'uuid';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from 'app/shared/user/user.service';
import { TranslateService } from "@ngx-translate/core";
import { AuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angular-6-social-login';
import { UserTokenService } from 'app/+auth/+services/user-token.service';
import { Configuration } from 'app/app-config.service';
import { LocalService } from 'app/core/sevices/local.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private modalRef: any;

  @Input() hideHiLogo: boolean;
  private user: SocialUser;
  public langFlag: string;
  public categoriesData: any;
  socialLinks: any;
  public mobileProfileTab: boolean = false;
  public signupPopup: boolean = false;
  public signInPopup: boolean = false;
  public countryPopup: boolean = false;
  public showSection: boolean = true;
  public countryList: any = [];
  privacyPolicy: string;
  settings: any;
  constructor(
    public i18N: I18nService,
    private utilMethods: UtilMethods,
    private layoutService: LayoutService,
    private _dashboardService: DashboardService,
    private _router: Router,
    private _checkoutService: CheckoutService,
    private modalService: NgbModal,
    private socialAuthService: AuthService,
    private _userTokenService: UserTokenService,
    private _translate: TranslateService,
    public _userService: UserService,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private _configuration: Configuration,
    private _localService: LocalService
  ) {
    this.privacyPolicy = _configuration.settings.PrivacyPolicy;
    this.settings = _configuration.settings;
    //console.log(this.settings);
  }
  ngOnInit() {
    this.langFlag = this.i18N.currentLanguage;
    this.i18N.getLanguageUpdates().subscribe((lang) => {
      this.langFlag = lang.language;
    })

    this.dashboardService.countryList.subscribe(message => this.countryList = message)

    this.checkUserInfo();

    this._checkoutService.getDeliveryCharge().subscribe(res => {
      if (res.IsSuccess) {
        this.socialLinks = res.Data;
        //console.log("del", this.socialLinks);
      }
    })
    this.categoriesData = this._localService.getData("CategoriesData", "object");
    if (this.categoriesData == null) {
      this._dashboardService.getCategories().subscribe(res => {
        this.categoriesData = res.Data.DashboardCategories;
        this._localService.saveData("CategoriesData", this.categoriesData);
        //console.log(res.Data);
      });
    }
  }
  translate() {
    this.i18N.currentLanguage == 'ae' ? this.langFlag = 'en' : this.langFlag = 'ae';
    this.utilMethods.showFullScreenLoader();

    this.i18N.setLanguage(this.langFlag);
    this.layoutService.onRtlCus(this.langFlag);
    this.utilMethods.hideFullScreenLoader()
  }
  sendCategoryId($event, item) {
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate(['/consumer/product/list'], {
        queryParams: { categoryId: item }
      });
    });
  }

  getCustomerSupport(param?) {
    this.modalRef = this.modalService.open(SupportComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
    if (param == 1)
      this.modalRef.componentInstance.openEmailSupport();
    this.modalRef.componentInstance.closeEvent.subscribe(el => {
      this.modalRef.close();
    })
  }

  chatInbox() {
    if (!localStorage.getItem('BuyerID')) {
      localStorage.setItem('BuyerID', localStorage.getItem('UserID'));
    }
    this.utilMethods.showFullScreenLoader();
    this._router.navigate(['/consumer/profile/inbox']);
  }
  addressNavigation(value) {
    sessionStorage.clear();
    if (value == 0) {
      this._router.navigate(['/consumer/checkout/AddressTemplate']);
    } else {
      this._router.navigate(['/consumer/checkout/AddAddressTemplate']);
    }
  }
  public userId: string;
  public storeId: string;
  public merchantId: string;
  public cartCount: number;
  public userName: string;
  public userImage: string;
  //public createdDate: any;
  public userForm: FormGroup;
  public loginForm: FormGroup;
  public failurePopup: boolean = false;


  checkUserInfo() {
    if (localStorage.getItem('LScurrentUser')) {
      this.merchantId = JSON.parse(localStorage.getItem('LScurrentUser')).MerchantID;
      this.storeId = JSON.parse(localStorage.getItem('LScurrentUser')).StoreID;
      this.userId = JSON.parse(localStorage.getItem('LScurrentUser')).UserID;
      this.userImage = JSON.parse(localStorage.getItem('LScurrentUser')).ProfileImage;
      this.userName = JSON.parse(localStorage.getItem('LScurrentUser')).FirstName + ' ' + JSON.parse(localStorage.getItem('LScurrentUser')).LastName;
    }
    // else if (!sessionStorage.getItem('anonymous-key')) {
    //   console.log("UUID:", uuid());
    //   this.userId = uuid();
    //   sessionStorage.setItem('anonymous-key', this.userId);
    // } else {
    //   this.userId = sessionStorage.getItem('anonymous-key');
    // }

    // this.currentUser = this._userService.currentUserValue;
    // console.log("current:",this.currentUser);

    // this.dashboardService.getItemsCountCart(this.userId).subscribe(res => {
    //   if (res) {
    //     localStorage.setItem("cartCount", res.Data);
    //     this.cartCount = res.Data;
    //     //console.log(res);
    //   }
    // })

    this.userForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      email: [localStorage.getItem('googleEmail') ? localStorage.getItem('googleEmail') : '', [Validators.required, Validators.email]],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)])
      ],
      seller: [false]
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.compose([
        Validators.required,
      ])
      ]
    });

    this.utilMethods.hideFullScreenLoader();

  }

  initForm() {
    this.userForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      email: [localStorage.getItem('googleEmail') ? localStorage.getItem('googleEmail') : '', [Validators.required, Validators.email]],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)])
      ],
      seller: [false]
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.compose([
        Validators.required,
      ])
      ]
    });
  }

  get userInfo(): any {
    if (localStorage.getItem('LScurrentUser')) {
      // this.userName = JSON.parse(localStorage.getItem('LScurrentUser')).FirstName + ' ' + JSON.parse(localStorage.getItem('LScurrentUser')).LastName;
      //this.cartCount = +(localStorage.getItem('cartCount'));
      // this.userImage = JSON.parse(localStorage.getItem('LScurrentUser')).ProfileImage;
      // return this.userName;
      return JSON.parse(localStorage.getItem('LScurrentUser')).FirstName;
    }
  }

  get createdDate(): any {
    if (localStorage.getItem('LScurrentUser')) {
      // this.userName = JSON.parse(localStorage.getItem('LScurrentUser')).FirstName + ' ' + JSON.parse(localStorage.getItem('LScurrentUser')).LastName;
      //this.cartCount = +(localStorage.getItem('cartCount'));
      // this.userImage = JSON.parse(localStorage.getItem('LScurrentUser')).ProfileImage;
      // return this.userName;
      return JSON.parse(localStorage.getItem('LScurrentUser')).CreateDate;
    }
  }

  get currentUser(): any {
    if (localStorage.getItem('LScurrentUser')) {
      // this.userName = JSON.parse(localStorage.getItem('LScurrentUser')).FirstName + ' ' + JSON.parse(localStorage.getItem('LScurrentUser')).LastName;
      //this.cartCount = +(localStorage.getItem('cartCount'));
      // this.userImage = JSON.parse(localStorage.getItem('LScurrentUser')).ProfileImage;
      // return this.userName;
      return true;
    } else {
      return false;
    }
  }

  checkValidation() {
    this.utilMethods.markControlsTouched(this.userForm);
    // if (this.userForm.controls.password.value != this.userForm.controls.cpassword.value)
    //   return;
    //     let abc = this.userForm.controls.phoneNumber.value.substring(0, 3);
    // if (this.userForm.controls.phoneNumber.value.substring(0, 3) != 971)
    // this.userForm.controls.phoneNumber.setErrors({'incorrect': true});

    if (!this.userForm.valid) {
      setTimeout(function () {
        document.querySelector('.has-error').scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
      return;
    }


    let userDetails = {
      objUserEntity: {
        FirstName: this.userForm.get('firstName').value,
        Email: this.userForm.get('email').value,
        Password: this.userForm.get('password').value,
        FacebookUserId: localStorage.getItem('facebookId') ? localStorage.getItem('facebookId') : '',
        GoogleUserId: localStorage.getItem('googleId') ? localStorage.getItem('googleId') : ''
      },
      objMerchantEntity: {
        UserID: "",
        merFirstName: this.userForm.get('firstName').value,
        merEmail: this.userForm.get('email').value,
        merPassword: this.userForm.get('password').value,
      }
    }

    //console.log('checkBox', this.userForm.get('seller').value);
    if (!this.userForm.get('seller').value) {
      userDetails.objMerchantEntity = null;
    }
    this.addRegistration(userDetails);
  }


  async addRegistration(data) {
    this.utilMethods.showFullScreenLoader();
    this._userTokenService.addRegistration(data)
      .subscribe(async srSuccess => {
        this.utilMethods.hideFullScreenLoader();
        if (srSuccess.IsSuccess == false) {
          let msg = await this._translate.get('already_account_exists').toPromise<void>();
          this.utilMethods.notificationDataError(msg);
          return;
        }
        else {
          localStorage.setItem('UserID', srSuccess.Data.UserID)
          // let msg = await this._translate.get('success_register').toPromise<void>();
          // this.utilMethods.notificationSuccess(msg);
          //console.log("UserSignUp:",srSuccess.Data)
          this._userService.setCurrentUser(srSuccess.Data);
          localStorage.setItem("Token", srSuccess.Data.Token)
          this.utilMethods.showFullScreenLoader();
          //this.checkUserInfo();
          //sessionStorage.setItem('Step','1');
          let route = this._router.url;
          if (this._router.url != '/') {
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([route]);
            });
          } else {
            this._router.navigateByUrl('/123', { skipLocationChange: true }).then(() => {
              this._router.navigate([route]);
            });
          }
          this.signupPopup = false;
          //this._router.navigate(['/auth/otp'], { skipLocationChange: true });

        }
      }, srError => {
        this.utilMethods.hideFullScreenLoader();
        this.utilMethods.notificationError(srError);
      });
  }

  async login(event) {
    this.clear();
    event.preventDefault();
    this.utilMethods.markControlsTouched(this.loginForm);
    if (!this.loginForm.valid) {
      return;
    }

    this.utilMethods.showFullScreenLoader();
    this._userTokenService.getLoginInfoDetails({
      Email: this.loginForm.get('email').value.toLowerCase(),
      Password: this.loginForm.get('password').value
    }).subscribe(async srSuccess => {
      this.utilMethods.hideFullScreenLoader();
      // this.activatedRoute.queryParams.subscribe(params => {
      //   this.redirectURL = params['returnUrl'];
      // });
      if (srSuccess.StatusCode == 200) {

        if (srSuccess.Data.UserID == "00000000-0000-0000-0000-000000000000") {
          this.failurePopup = true;
          // let msg = await this._translate.get('invalid_email_password').toPromise<void>();
          // this.utilMethods.notificationError(msg);
          return;
        }
        localStorage.setItem("UserID", srSuccess.Data.UserID)
        localStorage.setItem("Token", srSuccess.Data.Token);
        if (srSuccess.Data.IsVerifiedOTP == false) {
          this._userTokenService.regMobileNo = srSuccess.Data.PhoneNo;
          this._userTokenService.regEmail = srSuccess.Data.Email;
          this._userTokenService.regName = srSuccess.Data.FirstName;
          this._router.navigate(['/auth/otp']);
        }
        // else if (srSuccess.Data.StoreID == "00000000-0000-0000-0000-000000000000") {
        //     srSuccess.Data.PhoneNo = '+' + srSuccess.Data.PhoneNo;
        //     this._userService.setCurrentUser(srSuccess.Data);
        //     this._router.navigate(['/merchant/shop/add']);
        // }
        //Redirect to the url that was hit after login
        // else if (this.redirectURL) {
        //   console.log('UserData1', srSuccess.Data);
        //   //srSuccess.Data.PhoneNo = srSuccess.Data.PhoneNo;
        //   this._userService.setCurrentUser(srSuccess.Data);
        //   // sessionStorage.removeItem('anonymous-key');
        //   this._router.navigate([this.redirectURL]);
        // }
        // else if (sessionStorage.getItem('returnUrl')) {
        //   console.log('UserData1', srSuccess.Data);
        //   //srSuccess.Data.PhoneNo = srSuccess.Data.PhoneNo;
        //   this._userService.setCurrentUser(srSuccess.Data);
        //   //this._router.navigate([this.redirectURL]);
        //   // sessionStorage.removeItem('anonymous-key');
        //   this._location.back();
        // }
        else {
          //console.log('UserData2', srSuccess.Data);
          //srSuccess.Data.PhoneNo = srSuccess.Data.PhoneNo;
          this._userService.setCurrentUser(srSuccess.Data);
          // sessionStorage.removeItem('anonymous-key');
          this.utilMethods.showFullScreenLoader();
          this.signInPopup = false;
          // this.currentUser = this._userService.currentUserValue;
          // console.log("current:",this.currentUser);
          //sessionStorage.setItem('Step','1');
          //this.placeOrderStep = 1;
          // this.checkUserInfo();
          // console.log(this._router.url);
          let route = this._router.url;
          if (this._router.url != '/') {
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([route]);
            });
          } else {
            this._router.navigateByUrl('/123', { skipLocationChange: true }).then(() => {
              this._router.navigate([route]);
            });
          }

          //this._router.navigate(['/']);
          this.signInPopup = false;
        }


      }
    }, async srError => {
      this.utilMethods.hideLoader();
      let msg = await this._translate.get('network_error').toPromise<void>();
      this.utilMethods.notificationError(msg);
    });
  }

  validate(event) {
    var ew = event.which;
    if (48 <= ew && ew <= 57)
      return true;
    if (64 <= ew && ew <= 90)
      return true;
    if (97 <= ew && ew <= 122)
      return true;
    if (ew == 45 || ew == 46 || ew == 95)
      return true;

    return false;
  }

  fbLogin() {
    this.clear();
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
      this.user = userData;
      if (userData.id)
        this.getUserDetails(userData.id);
      localStorage.setItem('facebookId', this.user.id)
    });
  }

  googleLogin() {
    this.clear();
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
      this.user = userData;
      if (userData.id)
        this.getUserDetails(userData.email);
      localStorage.setItem('googleId', this.user.id)
      localStorage.setItem('googleEmail', this.user.email)
    });
  }

  getUserDetails(id) {
    this._userService.getUserDetails(id).subscribe((srSuccess: any) => {
      if (srSuccess.StatusCode == 217) {
        this.signInPopup = false;
        this.signupPopup = false;
      } else {
        this._userService.setCurrentUser(srSuccess.Data);
        //this.checkUserInfo();this.signInPopup = false;
        //sessionStorage.setItem('Step','1');
        // console.log(this._router.url);
        let route = this._router.url;
        if (this._router.url != '/') {
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([route]);
          });
        } else {
          this._router.navigateByUrl('/123', { skipLocationChange: true }).then(() => {
            this._router.navigate([route]);
          });
        }
        this.signInPopup = false;
        this.signupPopup = false;
        //this._router.navigate(['/'])
      }

    });
  }

  clear() {

    localStorage.removeItem('googleId');
    localStorage.removeItem('facebookId');
    localStorage.removeItem('googleEmail');
  }
}
