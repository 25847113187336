﻿import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';

import { Observable } from 'rxjs';
import { ServiceResponse } from '../../core/models/service-response.model';
import { NewServiceResponse } from 'app/core/models/new-service-response.model';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'app/app-config.service';


@Injectable()
export class UserTokenService {

    private loginUrl: string;
    addRegistrationUrl: string;
    sellerRegistrationUrl: string;
    forgotPasswordUrl: string;
    otpUrl: string;
    userConfirmationByOTPUrl: string;
    regMobileNo: string;
    regEmail: string;
    regName: string;
    regPassword: string;
    FacebookUserId: string;
    verifyProfileOtpUrl: string;
    verifyResetPassLink: string;
    resetPassword: string;

    constructor(private _http: HttpClient, private _configuration: Configuration) {

        this.loginUrl = `${_configuration.WebApi}api/merchant/API-Login`;
        this.addRegistrationUrl = `${_configuration.WebApi}api/user/API-registrationV1`;
        this.sellerRegistrationUrl = `${_configuration.WebApi}api/Seller/API-reg`;
        this.forgotPasswordUrl = `${_configuration.WebApi}api/merchant/API-ForgotPassword`;
        this.otpUrl = `${_configuration.WebApi}api/merchant/VerificationOTPCode`;
        this.userConfirmationByOTPUrl = `${_configuration.WebApi}api/merchant/VerifyUser`;
        this.verifyProfileOtpUrl =  `${_configuration.WebApi}api/merchant/VeirfyProfileOTP`;
        this.verifyResetPassLink =  `${_configuration.WebApi}api/user/Verifylink`;
        this.resetPassword =  `${_configuration.WebApi}api/user/ResetNewPassword`;
    }

    getLoginInfoDetails(loginModel: any): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(this.loginUrl, loginModel);
    }

    addRegistration(data: any): Observable<any> {
        return this._http.post<any>(this.addRegistrationUrl, data);
    }

    changePassword(data: any): Observable<any> {
        return this._http.post<any>(this.resetPassword, data);
    }

    sellerRegistration(data: any): Observable<any> {
        return this._http.post<any>(this.sellerRegistrationUrl, data);
    }

    verifyPasswordResetLink(userId: string): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(this.verifyResetPassLink + '?UserId=' + userId);
    }

    forgotPassword(email: string): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(this.forgotPasswordUrl + '?EmailId=' + email);
    }

    otpConfirmation(mobileNo: string, email: string, name: string): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(this.otpUrl + '?MobileNo=' + mobileNo + '&EmailID=' + email + '&Name=' + name);
    }

    userConfirmationByOTP(email: string, otpCode: string): Observable<NewServiceResponse> {

        return this._http.get<NewServiceResponse>(this.userConfirmationByOTPUrl + '?EmailID=' + email + '&OTPUser=' + otpCode);
    }

    verifyProfileOtp(phone: string, otpCode: string): Observable<NewServiceResponse> {
        let model ={
            PhoneNo: phone,
            OTPUser: otpCode
        }

        return this._http.post<NewServiceResponse>(this.verifyProfileOtpUrl , model);
    }
}